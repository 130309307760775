<template>
  <FDropdown
    class="currency-select"
    block-class="currency-select-popper"
    placement="bottom-end"
    show-indicator
    has-arrow
    :teleported="true"
    :disabled="disabled"
  >
    <template #title>
      <AppText size="15px" class="d-flex align-items-center font-medium">
        <CountryFlag :currency="modelValue" size="22px" style="margin-right: 8px;" />
        {{ modelValue }}
      </AppText>
    </template>

    <template #content>
      <CustomScroll style="height: 240px;">
        <DropdownItem
          v-for="item in currencyList"
          :key="item"
          no-paddings
          size="medium"
          @click="value = item.short"
        >
          <div class="d-flex align-items-center w-100">
            <AppText
              size="14px"
              :line-height="1"
              class="flex-grow-1"
            >
              {{ t(`currency.${item.countryCode}`) }}
            </AppText>
            <div class="d-flex justify-content-between" style="width: 80px;">
              <AppText size="14px" :opacity="0.4" style="width: 30px; text-align: right;">
                {{ item.symbol }}
              </AppText>
              <AppText size="14px" :opacity="0.4">
                {{ item.short }}
              </AppText>
            </div>
          </div>
        </DropdownItem>
      </CustomScroll>
    </template>
  </FDropdown>
</template>

<script>
import { defineComponent } from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';
import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { currencyList } from '@/common/data';
import { useModel } from '@/composables/useModel';

export default defineComponent({
  components: {
    DropdownItem,
    CountryFlag,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { value } = useModel(props, emit);

    return {
      value,
      currencyList,
    };
  },
});
</script>

<style scoped lang="scss">
.currency-select {
  border-radius: 8px;
  background: var(--color-F2F2F2);
  @include transition-base('background');

  &:hover {
    background: #e0e0e0;
  }

  :deep(.el-dropdown) {
    width: 108px;
    height: 36px !important;

    .el-dropdown--default {
      width: 100%;
    }

    .f-dropdown--title {
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px 0 12px;
      width: 100%;
    }
  }
}

:global(.currency-select-popper) {
  width: 280px;
}

:global(.currency-select-popper .el-scrollbar) {
  height: calc(40px * 6);
}
</style>
