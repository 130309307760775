<template>
  <FInput
    v-model="email"
    has-prefix
    autofocus
    size="big"
    :placeholder="t('signup.label.emailPlaceholder')"
    :validation-error="error"
  >
    <template #prefix>
      <AppIcon
        name="mail"
        :opacity="0.4"
        size="18px"
        is-img-tag
      />
    </template>
    <template #suffix>
      <Transition name="show">
        <DelayButton
          v-if="hasResetButton"
          ref="resendButton"
          :delay="delayInSeconds"
          size="x-small"
          type="inner"
          class="resend-button"
          @click="onResend"
        >
          {{ t('common.resend') }}
        </DelayButton>
      </Transition>
    </template>
  </FInput>
</template>

<script setup>
import {
  computed, onBeforeMount, onBeforeUnmount, ref,
} from 'vue';
import dayjs from 'dayjs';

import DelayButton from '@/components/Buttons/DelayButton.vue';

import { router } from '@/router';
import { useTemporaryStore } from '@/store';

import { intervalId, runRevokeTimer } from '../useRevokeTimer';
import { resendEmail } from '../api';

defineProps({
  error: {
    type: Object,
    default: () => {},
  },
});

const email = defineModel({ type: String, default: '' });

const { store, registration, currentStep } = useTemporaryStore();
const hasResetButton = computed(() => currentStep.value === 4);

const resendButton = ref(null);
const delayInSeconds = ref(59);
const onResend = async () => {
  store.updateRegistrationState({ revokeAt: dayjs().add(30, 'minute') });
  runRevokeTimer(registration.value.revokeAt);
  const { data, isSuccess, error } = await resendEmail();
  const isRequestBlockedMessage = error?.data?.errors?.REQUEST_BLOCKED?.length;

  if (isSuccess) {
    delayInSeconds.value = data.timeInSeconds;
    resendButton.value.setDelayImmediately();
  } else if (isRequestBlockedMessage) {
    delayInSeconds.value = error?.data?.errors?.REQUEST_BLOCKED[0]?.timeInSeconds;
    resendButton.value.setDelayImmediately();
  }
};

onBeforeMount(() => {
  if (registration.value.revokeAt && dayjs(registration.value.revokeAt) > dayjs()) {
    runRevokeTimer(registration.value.revokeAt);
  } else if (registration.value.revokeAt) {
    router.push('/session-expired');
  }
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});
</script>

<style scoped lang="scss">
:deep(.el-button > span) {
  color: var(--color-black-04);
}
:deep(.el-input .el-input__suffix) {
  margin-right: 0;
}
</style>
