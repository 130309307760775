import { ref } from 'vue';

import { router } from '@/router';
import { runCronJob } from '@/utils/functions';

export const intervalId = ref(null);

export const runRevokeTimer = (time) => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }

  intervalId.value = runCronJob(time, () => {
    router.push('/session-expired');
  });
};
