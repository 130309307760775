<template>
  <div class="auth-connected">
    <AppIcon name="check-done" size="18px" fill="var(--color-green)" class="mr-3" />
    <AppText size="15px" :line-height="1.5">
      {{ t('signup.label.authAppConnected') }}
    </AppText>
    <FSpacer />
    <FButton
      size="x-small"
      type="inner"
      class="reset-button"
      @click="onStepBack"
    >
      {{ t('common.reset') }}
    </FButton>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useTemporaryStore } from '@/store';

export default defineComponent({
  setup() {
    const { store } = useTemporaryStore();
    const onStepBack = () => {
      store.updateRegistrationState({ email: null, step: 2 });
    };

    return { onStepBack };
  },
});
</script>

<style scoped lang="scss">
.auth-connected {
  background: var(--color-F7F7F7);
  border-radius: 4px;
  height: 52px;
  padding: 20px 12px 20px 20px;
  margin-bottom: 12px;

  @include flex-center;
  justify-content: space-between;
}
.reset-button {
  & > :deep(span) {
    color: var(--color-black-04);
  }
  &:hover {
    & > :deep(span) {
      color: var(--color-error);
    }
  }
}
</style>
