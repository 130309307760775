import {
  computed, onBeforeMount, reactive, ref,
} from 'vue';
import dayjs from 'dayjs';

import {
  codeRule, companyNameRule, emailRule, fieldValidation,
  useValidation, yup,
} from '@/validation';
import { i18n } from '@/plugins/localization';
import { useTemporaryStore, useStore } from '@/store';

import { runRevokeTimer } from './useRevokeTimer';
import {
  completeRegistration,
  merchantRegistrate,
  twoFaEnable,
  verifyEmailHandler,
} from './api';

const currency = ref('USD');
const isProcessing = ref(false);

const initCompanyName = (requestFields) => {
  const { fieldsErrors } = useValidation();

  const validationSchema = yup.object().shape({
    companyName: companyNameRule,
  });

  const { setNextStep } = useTemporaryStore();

  const onValidate = async () => {
    isProcessing.value = true;
    const { isSuccess, errorObject } = await merchantRegistrate({
      companyName: requestFields.companyName,
      baseCurrency: currency.value,
    });
    if (isSuccess) {
      setNextStep();
    }
    if (!isSuccess) {
      fieldsErrors.value = errorObject.fields;
    }
    isProcessing.value = false;

    return { errorObject };
  };

  return {
    currency,

    fieldsErrors,
    validationSchema,
    requestFields,
    onValidate,
  };
};

const initCode = (requestFields) => {
  const { setNextStep } = useTemporaryStore();

  const {
    validationSchema,
    fieldsErrors,
    processError,
  } = fieldValidation({ fieldName: 'code', rule: codeRule });

  const onValidate = async () => {
    isProcessing.value = true;
    const { isSuccess, errorObject } = await twoFaEnable(requestFields.code);

    if (isSuccess) {
      setNextStep();
      requestFields.code = '';
    } else {
      processError(errorObject, 'wrongToken', 'code');
    }
    isProcessing.value = false;
  };

  return {
    fieldsErrors,
    validationSchema,
    requestFields,
    onValidate,
  };
};

const initEmail = (requestFields) => {
  const { store, registration, setNextStep } = useTemporaryStore();
  const { language } = useStore();

  const {
    validationSchema,
    fieldsErrors,
  } = fieldValidation({ fieldName: 'email', rule: emailRule });

  const onValidate = async () => {
    isProcessing.value = true;
    const { isSuccess, errorObject } = await completeRegistration({
      email: requestFields.email,
      language,
    });

    if (isSuccess) {
      store.updateRegistrationState({
        email: requestFields.email,
        revokeAt: dayjs().add(30, 'minute'),
      });
      setNextStep();

      setTimeout(() => { requestFields.code = ''; }, 1000);

      runRevokeTimer(registration.value.revokeAt);
    } else {
      fieldsErrors.value = errorObject.fields;
    }
    isProcessing.value = false;

    return { errorObject };
  };

  return {
    fieldsErrors,
    validationSchema,
    requestFields,

    onValidate,
  };
};

const initVerificationCode = (requestFields) => {
  const { store, setNextStep } = useTemporaryStore();

  const {
    validationSchema,
    fieldsErrors,
  } = fieldValidation({ fieldName: 'code', rule: codeRule });

  const onValidate = async () => {
    isProcessing.value = true;
    const {
      uid, isSuccess, error, errorObject,
    } = await verifyEmailHandler(requestFields.code);
    if (isSuccess) {
      setNextStep();
      store.updateRegistrationState({ uid });
    } else {
      fieldsErrors.value.code = [{
        message: error?.data?.message,
      }];
    }

    isProcessing.value = false;
    return { errorObject };
  };

  return {
    validationSchema,
    fieldsErrors,
    onValidate,
  };
};

export const init = () => {
  const { currentStep } = useTemporaryStore();

  const fields = {
    companyName: '',
    code: '',
    email: '',
  };

  const requestFields = reactive(fields);

  const {
    validationSchema: nameValidation,
    fieldsErrors: nameErrors,
    onValidate: onNameValidate,
  } = initCompanyName(requestFields);

  const {
    validationSchema: codeValidation,
    fieldsErrors: codeErrors,
    onValidate: onCodeValidate,
  } = initCode(requestFields);

  const {
    validationSchema: emailValidation,
    fieldsErrors: emailErrors,
    onValidate: onEmailValidate,
  } = initEmail(requestFields);

  const {
    validationSchema: verifyValidation,
    fieldsErrors: verifyErrors,
    onValidate: onVerifyValidate,
  } = initVerificationCode(requestFields);

  const validationSchema = computed(() => {
    if (currentStep.value === 1) {
      return nameValidation;
    }
    if (currentStep.value === 2) {
      return codeValidation;
    }
    if (currentStep.value === 3) {
      return emailValidation;
    }
    if (currentStep.value === 4) {
      return verifyValidation;
    }
    return null;
  });

  const fieldsErrors = computed(() => {
    if (currentStep.value === 1) {
      return nameErrors.value;
    }
    if (currentStep.value === 2) {
      return codeErrors.value;
    }
    if (currentStep.value === 3) {
      return emailErrors.value;
    }
    if (currentStep.value === 4) {
      return verifyErrors.value;
    }
    return null;
  });

  const onValidate = computed(() => {
    if (currentStep.value === 1) {
      return onNameValidate;
    }
    if (currentStep.value === 2) {
      return onCodeValidate;
    }
    if (currentStep.value === 3) {
      return onEmailValidate;
    }
    if (currentStep.value === 4) {
      return onVerifyValidate;
    }
    return null;
  });

  onBeforeMount(() => {
    const { registration } = useTemporaryStore();

    if (registration.value?.companyName) {
      requestFields.companyName = registration.value?.companyName;
    }
    if (registration.value?.currency) {
      currency.value = registration.value?.currency;
    }
    if (registration.value?.email) {
      requestFields.email = registration.value?.email;
    }
  });

  const buttonType = computed(() => {
    const isNametStep = currentStep.value === 1 && !requestFields.companyName;
    const isEmailStep = currentStep.value === 3 && !requestFields.email;
    const isCodeStep = currentStep.value === 4 && !requestFields.code;

    if (isNametStep || isEmailStep || isCodeStep) {
      return 'plain';
    }

    return 'primary';
  });

  const { t } = i18n.global;
  const buttonText = computed(() => {
    if (currentStep.value === 3) {
      return t('signup.button.confirmEmail');
    }
    if (currentStep.value === 4) {
      return t('signup.button.createAccount');
    }

    return t('common.continue');
  });

  return {
    currency,
    isProcessing,

    buttonType,
    buttonText,

    requestFields,
    validationSchema,
    fieldsErrors,
    onValidate,
  };
};
