<template>
  <TransitionGroup name="fade-absolute">
    <ActionForm
      v-if="isOngoingRegistration"
      key="action-form"
      class="action-form"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      :is-bubble-notification-visible="false"
      @validate="validate"
    >
      <template #default="{ errors, action }">
        <div class="d-flex flex-column" style="margin-bottom: 30px;">
          <AppText size="18px" mb="8px" class="font-medium">
            {{ t('signup.title') }}
          </AppText>
          <AppText :opacity="0.4">
            {{ t('signup.description') }}
          </AppText>
        </div>
        <div style="margin-bottom: 20px;">
          <CompanyNameInput
            v-model:name="requestFields.companyName"
            v-model:currency="currency"
            v-model:is-name-chosen="isNameChosen"
            :blocked="isRequestBlockedOnFirstStep"
            :error="errors?.companyName"
            @enter="action"
          />
        </div>

        <SimpleTransition
          :style="`
          min-height: ${currentStep === 2 ? '162px' : ''}${[3, 4].includes(currentStep) ? '162px' : ''};
          overflow: hidden;
        `"
        >
          <QrCodeInput
            v-if="currentStep === 2"
            v-model="requestFields.code"
            :text="`Switch: ${requestFields.companyName} (${currency})`"
            style="margin-top: 12px"
            :error="errors?.code?.message"
          />

          <div v-if="currentStep === 3 || currentStep === 4">
            <AuthConnectedAlert />
            <EmailStep
              v-model="requestFields.email"
              :error="errors?.email"
            />
          </div>
        </SimpleTransition>

        <Transition name="show">
          <template v-if="currentStep === 4">
            <div class="d-flex flex-column align-items-center mt-2">
              <AppText>{{ t('signup.label.codeWasSent') }}</AppText>

              <SplitInput
                v-model="requestFields.code"
                type="dashed"
                :state="errors?.code ? 'error' : ''"
                is-auto-clear-error
                style="margin-top: 18px;"
                hide-error-message
              />
            </div>
          </template>
        </Transition>

        <div v-if="isOngoingRegistration" class="d-flex justify-content-center mt-6" style="margin-bottom: -10px">
          <DelayButton
            v-show="currentStep === 1"
            ref="firstStepButton"
            :delay="requestBlockedDelay"
            :loading="isProcessing"
            :disabled="currentStep === 1 && errors?.companyName?.message"
            @click="action"
            @clear="onDelayCleader"
          >
            {{ t('common.continue') }}
          </DelayButton>
          <DelayButton
            v-show="currentStep === 3"
            ref="verificationEmailButton"
            :delay="requestBlockedDelay"
            :loading="isProcessing"
            :disabled="currentStep === 3 && errors?.code?.message"
            type="primary"
            @click="action"
            @clear="onDelayCleader"
          >
            {{ t('signup.button.sendCode') }}
          </DelayButton>
          <FButton
            v-show="![1, 3].includes(currentStep)"
            :type="buttonType"
            :loading="isProcessing"
            @click="action"
          >
            {{ buttonText }}
          </FButton>
        </div>
      </template>
    </ActionForm>

    <RecoveryCodes v-if="currentStep === 5" key="recovery-codes" />
    <FinalStep v-if="currentStep === 6" key="final-step" />
  </TransitionGroup>
</template>

<script>
import { computed, ref, watch } from 'vue';

import DelayButton from '@/components/Buttons/DelayButton.vue';
import SimpleTransition from '@/components/Animation/SimpleTransition.vue';
import SplitInput from '@/components/Inputs/SplitInput.vue';

import { ActionForm } from '@/validation';
import { router } from '@/router';
import { usePageLeaving } from '@/composables/usePageLeaving';
import { useSocket } from '@/composables/useSocket';
import { useTemporaryStore } from '@/store';

import { init } from './useRegistration';
import CompanyNameInput from './Steps/CompanyNameInput';
import EmailStep from './Steps/EmailStep.vue';
import FinalStep from './Steps/FinalStep.vue';
import QrCodeInput from './Steps/QrCodeInput.vue';
import RecoveryCodes from './Steps/RecoveryCodes.vue';
import AuthConnectedAlert from './components/AuthConnectedAlert.vue';

export default {
  name: 'SignUp',
  components: {
    SplitInput,
    ActionForm,
    SimpleTransition,
    AuthConnectedAlert,
    DelayButton,

    CompanyNameInput,
    QrCodeInput,
    EmailStep,
    RecoveryCodes,
    FinalStep,
  },
  setup() {
    const {
      store,
      currentStep,
      registration,
      merchantId,
    } = useTemporaryStore();

    const {
      currency,
      isProcessing,

      buttonType,
      buttonText,

      requestFields,
      validationSchema,
      fieldsErrors,
      onValidate,
    } = init();

    watch(computed(() => requestFields.email), (newVal, oldVal) => {
      if (newVal && oldVal) {
        registration.value.step = 3;
        requestFields.code = '';
        store.updateRegistrationState({ email: null });
      }
    });

    const isOngoingRegistration = computed(() => [1, 2, 3, 4].includes(currentStep.value));

    const verificationEmailButton = ref(null);
    const firstStepButton = ref(null);

    const isNameChosen = computed(() => store.registration.step > 1);

    usePageLeaving({
      watchedRef: currentStep,
      condition: (val, routeName) => val.value > 1 && !['session-expired', 'dashboard'].includes(routeName?.toLowerCase()),
      initialValue: 1,
    });

    const requestBlockedDelay = ref(59);
    const isRequestBlocked = ref(false);

    const isRequestBlockedOnFirstStep = computed(() => isRequestBlocked.value && currentStep.value === 1);
    const isRequestBlockedOnThirdStep = computed(() => isRequestBlocked.value && currentStep.value === 3);

    const validate = async () => {
      if (!isRequestBlocked.value) {
        const result = await onValidate.value();

        const requestBlockedError = result?.errorObject?.error?.data?.errors?.REQUEST_BLOCKED;
        isRequestBlocked.value = Boolean(requestBlockedError?.length);
        const secondsLeft = requestBlockedError && requestBlockedError[0]?.timeInSeconds;

        if (isRequestBlocked.value && secondsLeft) {
          requestBlockedDelay.value = secondsLeft;
        }

        if (isRequestBlockedOnFirstStep.value) {
          firstStepButton.value.setDelayImmediately();
        }

        if (isRequestBlockedOnThirdStep.value) {
          verificationEmailButton.value.setDelayImmediately();
        }
      }
    };

    const onDelayCleader = () => {
      isRequestBlocked.value = false;
    };

    let currentSocket = {};

    const merchantPruneListener = () => {
      const { addSocketListener, socket } = useSocket(merchantId.value);
      currentSocket = socket;
      socket.on('connect', () => {
        socket.emit('room', merchantId.value);
      });
      addSocketListener('merchant-pruned', onGetMessage);
    };

    const onGetMessage = (data) => {
      if (data?.uid === store.registration.uid) {
        router.push('/session-expired');
        currentSocket.disconnect('merchant-pruned');
      }
    };

    if (store.registration.uid) {
      merchantPruneListener();
    }

    watch(() => store.registration.uid, (newVal) => {
      if (newVal) {
        merchantPruneListener();
      }
    });

    return {
      registration,
      currency,
      isProcessing,
      currentStep,

      requestFields,
      validationSchema,
      fieldsErrors,
      validate,

      onDelayCleader,

      buttonType,
      buttonText,

      firstStepButton,
      verificationEmailButton,
      requestBlockedDelay,

      isOngoingRegistration,
      isNameChosen,

      isRequestBlockedOnFirstStep,
    };
  },
};
</script>
