<template>
  <div class="position-relative">
    <SimpleTransition>
      <FInput
        v-model="name"
        class="company-name"
        :class="{
          'company-name--is-disabled': isNameChosen,
        }"
        :placeholder="t('signup.companyName.placeholder')"
        has-prefix
        :disabled="isNameChosen"
        :validation-error="error"
        :maxlength="25"
        size="big"
        autofocus
        @keypress.enter.stop="onPressEnter"
      >
        <template #prefix>
          <AppIcon
            name="switch-logo"
            :opacity="0.4"
            size="24px"
            is-img-tag
          />
        </template>
        <template #suffix>
          <div v-show="isNameChosen" class="edit-icon-wrap" @click="onEdit">
            <AppIcon name="pencil" size="18px" is-img-tag />
          </div>
          <CurrencySelector v-show="!isNameChosen" v-model="currency" />
        </template>
      </FInput>
    </SimpleTransition>
    <div v-if="isNameChosen" class="chosen-state">
      <div class="chosen-state--dublication">
        {{ name }}
      </div>
      <DotSeparator color="var(--color-black-01)" />
      <AppText size="15px" :opacity="0.4" class="flex-grow-1">
        {{ currency }}
      </AppText>
    </div>
  </div>
  <AppText
    v-if="!error"
    :line-height="1.5"
    color="var(--color-black-04)"
    class="hint"
    mt="8px"
    variant="div"
  >
    <i18n-t keypath="signup.invitation.currencyDescription">
      <span class="font-medium">{{ t('signup.invitation.currencyDescriptionSubtext') }}</span>
    </i18n-t>
  </AppText>
</template>

<script setup>
import { watch } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import SimpleTransition from '@/components/Animation/SimpleTransition.vue';

import { useTemporaryStore } from '@/store';

import CurrencySelector from './CurrencySelector.vue';

const props = defineProps({
  blocked: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['enter']);

const name = defineModel('name', { type: String, default: '' });
const currency = defineModel('currency', { type: String, default: '' });
const isNameChosen = defineModel('isNameChosen', { type: Boolean, default: false });

const { store } = useTemporaryStore();
const watchHandler = () => { store.registration.step = 1; };

watch(name, watchHandler);
watch(currency, watchHandler);

const onEdit = () => {
  watchHandler();
  isNameChosen.value = false;
};

const onPressEnter = (event) => {
  event.preventDefault();
  if (!props.blocked) {
    emit('enter');
  }
};
</script>

<style scoped lang="scss">
.company-name {
  position: relative;
  top: -1px;
  background-color: var(--color-white);
  @include transition-base(background-color);

  &--is-disabled {
    :deep(.el-input.is-disabled .el-input__wrapper) {
      background-color: var(--color-F7F7F7);
      box-shadow: none;
      border: 1px solid var(--color-F7F7F7);
      cursor: default;
      .el-input__inner {
        -webkit-text-fill-color: var(--color-black-04);
        color: var(--color-black-04) !important;
      }
    }
  }

  :deep(.el-input) {
    .el-input__wrapper {
      padding-left: 9px;
    }

    .el-input__prefix {
      position: relative;
      padding-left: 0;
      padding-right: 22px;

      :after {
        content: '';
        position: absolute;
        top: 50%;
        left: 38px;
        transform: translate(0, -50%);
        width: 1px;
        height: 24px;
        background-color: var(--color-black-01);
        z-index: 1;
      }
    }
  }
}

.edit-icon-wrap {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  @include flex-center;
  @include transition-base('background-color');

  &:hover {
    background-color: var(--color-black-01);
  }

  :deep(.icon) {
    opacity: 0.4;
  }
}

.chosen-state {
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  font-size: 15px;
  left: 68px;

  &--dublication{
    color: transparent;
  }
}
</style>
